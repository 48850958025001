.PayReports {
    .table-row-light {
        background-color: #ffffff;
    }
    .table-row-dark {
        background-color: #f8f8f8;
    }

    .ant-table-thead {
        .ant-table-cell {
            background: #eaeaea;

            &:hover {
                background: #eaeaea;
            }
        }
    }

    .download-button {
        display: flex;
        justify-content: flex-end;
        margin-bottom: var(--enjoy-padding-xs);
        button {
            background-color: var(--enjoy-orange);
            border-color: var(--enjoy-orange);
        }
    }
    .ant-table-column-sorter {
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .ant-pagination-item {
        &-active {
            border-color: var(--enjoy-orange);
            a {
                color: var(--enjoy-orange);
            }
        }
        &:hover {
            border-color: var(--enjoy-orange);
            a {
                color: var(--enjoy-orange);
            }
        }
    }

    .ant-pagination-prev {
        button {
            &:hover {
                border-color: var(--enjoy-orange);
                color: var(--enjoy-orange);
            }
        }
    }

    .ant-pagination-next {
        button {
            &:hover {
                color: var(--enjoy-orange);
                border-color: var(--enjoy-orange);
            }
        }
    }

    .ant-pagination-disabled {
        .ant-pagination-item-link:hover {
                border-color: #d9d9d9;
                color: rgba(0, 0, 0, 0.25);
        }
    }
}
