.Login {
    background-image: url("../../assets/fondo_casino_gris.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    .left {
        display: flex;
        flex-direction: column;

        .title {
            margin-top: 54px;
            color: var(--enjoy-grey);
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 3.09px;
            line-height: 24px;
        }

    }

    .right {
        width: 239px;

        .signin-button {
            width: 100%;
            background-color: var(--enjoy-orange);
            border-color: var(--enjoy-orange);
        }

        .forgot-password{
            display: block;
            color: #319BF5;
            text-decoration: underline;
            margin-bottom: 29px;
        }
    }

    .divider {
        height: 296px;
    }

    .left + .divider {
        margin-left: 66px;
    }

    .divider + .right {
        margin-left: 66px;
    }
}