.Chip {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    width: 80px;
    height: fit-content;
    border-radius: 8px;
    font-size: 12px;
    padding: 5px;
}