.ReportsLayout {
    .dragger {
        margin-top: var(--enjoy-padding-s);
        padding: var(--enjoy-padding) 0;

        p {
            margin: 0px;
            margin-top: var(--enjoy-padding-xxs);
        }

        &-icon {
            svg {
                color: var(--enjoy-grey-icon);
            }
        }
        button {
            color: var(--enjoy-orange);
            border-color: var(--enjoy-orange);

            &:hover {
                color: var(--enjoy-orange);
                border-color: var(--enjoy-orange);
            }

            &:active {
                color: var(--enjoy-orange);
                border-color: var(--enjoy-orange);
            }

            &:focus {
                color: var(--enjoy-orange);
                border-color: var(--enjoy-orange);
            }
        }
    }

    .upload-button {
        display: flex;
        justify-content: flex-end;
        margin-top: var(--enjoy-padding-s);
        button {
            background-color: var(--enjoy-orange);
            border-color: var(--enjoy-orange);
        }
    }

    .ant-upload-drag:hover {
        border-color: var(--enjoy-orange) !important;
    }
}
