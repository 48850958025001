.TopMenu {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 0 var(--enjoy-padding);

    .ant-menu-item { 
        min-width: 170px;
        text-align: center;
    }

    .ant-menu-item:hover {
        border-bottom: 2px solid var(--enjoy-orange) !important;
        border-bottom: var(--enjoy-orange) !important;
        color: var(--enjoy-orange) !important;
        a {
            color: var(--enjoy-orange) !important;
        }
    }

    .ant-menu-item-selected{
        border-bottom: 2px solid var(--enjoy-orange) !important;
        color: var(--enjoy-orange) !important;
        a {
            color: var(--enjoy-orange) !important;
        }

        &:hover {
            @extend .ant-menu-item-selected;
        }
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
        border-bottom: none
    }
    
    .ant-menu-item-active:hover {
        border-bottom: 2px solid var(--enjoy-orange) !important;
        color: var(--enjoy-orange) !important;
        a {
            color: var(--enjoy-orange) !important;
        }
    }

    img {
        width: 107px;
    }
}